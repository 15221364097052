import { capitalDesignTokens } from '@opengov/capital-mui-theme';

export const loadingContainerStyle = {
    marginTop: 2,
};

export const loadingErrorContainerStyle = {
    ...loadingContainerStyle,
    marginBottom: 2,
    textAlign: 'center',
};

export const scoreContainerStyle = {
    border: `1px solid ${capitalDesignTokens.semanticColors.callToAction.primaryActionLight}`,
    borderRadius: '4px',
};

export const scoreListItemStyle = {
    alignItems: 'center',
    display: 'flex',
    gap: 2,
    paddingX: 2,
    paddingY: 1,
};

export const scoreListItemDividerStyle = {
    ...scoreListItemStyle,
    borderTop: `1px solid ${capitalDesignTokens.semanticColors.callToAction.primaryActionLight}`,
};

export const modalTitleDescriptionStyle = {
    color: capitalDesignTokens.semanticColors.foreground.secondary,
    paddingTop: 1,
};

export const modalFooterStyle = {
    alignItems: 'center',
    backgroundColor: capitalDesignTokens.semanticColors.background.secondary,
    color: capitalDesignTokens.semanticColors.foreground.secondary,
    display: 'flex',
    gap: 1,
    justifyContent: 'flex-start',
    paddingY: 1.5,
};

export const modalBodyStyle = {
    paddingTop: 2,
};

export const vendorsListStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
};

export const vendorDescriptionStyle = {
    color: capitalDesignTokens.semanticColors.foreground.secondary,
    fontStyle: 'italic',
};

export const vendorCardContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
};
