import { Warning as WarningIcon } from '@mui/icons-material';
import { Box, Dialog, Typography } from '@og-pro/ui';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Body } from './Body';
import { getDiscoverVendorData } from './helper';
import { modalBodyStyle, modalFooterStyle, modalTitleDescriptionStyle } from './styles';
import { getProjectJS } from '../selectors';
import { hideVendorDiscoveryModal } from '../../../actions/govProjects';
import { discoverVendors } from '../../../actions/vendorDiscovery';

const VendorDiscoveryModalTitle = () => (
    <>
        <Typography variant="h3">Discover Vendors</Typography>
        <Typography sx={modalTitleDescriptionStyle} variant="bodySmall">
            An AI-powered tool that uses the web to find vendors that match your project needs.
            Please note that this tool only provides website and address information.
        </Typography>
    </>
);

const VendorDiscoveryModalFooter = () => (
    <>
        <WarningIcon fontSize="small" />
        <Typography variant="bodyXSmall">
            This is an AI tool. It pulls information from the internet. May produce inaccurate
            responses.
        </Typography>
    </>
);

export const VendorDiscoveryModal = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(null);
    const [vendors, setVendors] = useState([]);
    const [searchNum, setSearchNum] = useState(1);

    const project = useSelector(getProjectJS);

    const dispatch = useDispatch();
    const hideModal = () => dispatch(hideVendorDiscoveryModal());

    // Dev Note: To avoid long wait times when fetching data from OpenAI, you can enable dummy data and
    // set state outside the loading event. Dummy data can be imported from here:
    // import { dummyVendors } from './dummyData';
    // setVendors(dummyVendors);
    useEffect(() => {
        setIsLoading(true);
        setLoadingError(null);
        setVendors([]);

        const data = getDiscoverVendorData(project);
        dispatch(discoverVendors(project, data))
            .then((foundVendors) => {
                setVendors(foundVendors);
                setIsLoading(false);
            })
            .catch((error) => {
                setLoadingError(error.message);
                setIsLoading(false);
            });
    }, [searchNum]);

    const retrySearch = () => setSearchNum(searchNum + 1);

    return (
        <Dialog
            actionsProps={{ sx: modalFooterStyle }}
            contentProps={{ dividers: true }}
            dialogActions={<VendorDiscoveryModalFooter />}
            dialogTitle={<VendorDiscoveryModalTitle />}
            fullWidth
            maxWidth="sm"
            onClose={hideModal}
            open
        >
            <Box sx={modalBodyStyle}>
                <Body
                    isLoading={isLoading}
                    loadingError={loadingError}
                    project={project}
                    retrySearch={retrySearch}
                    vendors={vendors}
                />
            </Box>
        </Dialog>
    );
};
