import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { CircularProgress, IconButton } from '@og-pro/ui';
import { Download as DownloadIcon } from '@mui/icons-material';
import { projectStatusesDict } from '@og-pro/shared-config/projects';
import { statuses } from '@og-pro/shared-config/electronicSignatures';

import { showSnackbar } from '../../../../actions/notification';
import { downloadElectronicSignatureFile } from '../../../../actions/project/electronicSignatures';

export const DownloadDocumentButton = ({ contractDocument }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const download = useCallback(async () => {
        try {
            setLoading(true);
            await dispatch(
                downloadElectronicSignatureFile({
                    projectId: contractDocument.id,
                    electronicSignatureId: contractDocument.electronicSignature.id,
                })
            );
        } catch (e) {
            dispatch(showSnackbar('Failed to download the document', { isError: true }));
        } finally {
            setLoading(false);
        }
    }, [dispatch, contractDocument]);

    if (contractDocument.status !== projectStatusesDict.FINAL) {
        return null;
    }

    if (contractDocument.electronicSignature?.status !== statuses.COMPLETED) {
        return null;
    }

    return (
        <IconButton
            disabled={loading}
            onClick={() => download()}
            qaTag="contractDocuments-downloadSignedDocument"
            size="small"
        >
            {loading ? <CircularProgress size="small" /> : <DownloadIcon fontSize="small" />}
        </IconButton>
    );
};

DownloadDocumentButton.propTypes = {
    contractDocument: PropTypes.shape({
        electronicSignature: PropTypes.shape({
            currentSigners: PropTypes.number,
            id: PropTypes.number.isRequired,
            provider: PropTypes.string,
            providerId: PropTypes.string,
            status: PropTypes.string.isRequired,
            totalSigners: PropTypes.number,
        }),
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired,
    }).isRequired,
};
