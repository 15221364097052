import React from 'react';

import { Box, Button, Chip } from '@og-pro/ui';

import { useSelector } from 'react-redux';

import { getFormValues } from 'redux-form';

import {
    actionPanelNavContainerStyle,
    counterStyle,
    jumpToStyle,
    sectionButtonStyle,
} from './styles';
import { getRequisitionsCreateSections } from '../../../RequisitionsCreate/selectors';
import { PURCHASE_DETAILS, sectionsTypes } from '../../../../../constants/requisitionsCreate';
import { scrollToSection } from '../../../RequisitionsCreate/utils';
import { formConfig } from '../../../RequisitionsCreate/RequisitionsCreateForms/form';

export const ActionPanelNav = () => {
    const sections = useSelector(getRequisitionsCreateSections);
    const formValues = useSelector((state) => getFormValues(formConfig.form)(state));

    const priceItems = formValues?.priceTable?.priceItems || [];

    const handleMouseDown = (event) => {
        event.preventDefault();
        event.currentTarget.blur();
    };

    return (
        <Box {...actionPanelNavContainerStyle}>
            <Box alignItems="center" display="flex" gap={1}>
                <Box component="span" {...jumpToStyle}>
                    Jump To:
                </Box>
                {sectionsTypes.map((sectionKey) => {
                    const section = sections[sectionKey];

                    if (!section || section.disabled) {
                        return null;
                    }

                    return (
                        <Button
                            color="secondary"
                            key={`jumpTo-${sectionKey}`}
                            onClick={() =>
                                scrollToSection(sectionKey, {
                                    offset: -140, // To account for the Action Panel sticky header height
                                })
                            }
                            onMouseDown={handleMouseDown} // Used to remove focus outline
                            qaTag={`jumpToReqSection-${sectionKey}`}
                            size="small"
                            sx={sectionButtonStyle}
                            variant="text"
                        >
                            {section.name}
                            {sectionKey === PURCHASE_DETAILS ? (
                                <Box ml={0.5}>
                                    <Chip
                                        color="default"
                                        label={priceItems.length}
                                        size="extraSmall"
                                        sx={counterStyle}
                                        variant="filled"
                                    />
                                </Box>
                            ) : null}
                        </Button>
                    );
                })}
            </Box>
        </Box>
    );
};
