import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@og-pro/ui';

import { getAccuracyScoring } from './helper';
import { ScoreDisplay } from './ScoreDisplay';

export const LoadingDisplay = ({ project }) => {
    const { score, suggestions } = getAccuracyScoring(project);

    const [isInitialLoading, setIsInitialLoading] = useState(true);

    // This is purely to provide some visual progress during the very long wait time for the data
    useEffect(() => {
        // Score calculating message will display until this timeout is reached
        setTimeout(() => {
            setIsInitialLoading(false);
        }, 4000);
    }, []);

    if (isInitialLoading) {
        return (
            <Typography sx={{ marginBottom: 5.5, textAlign: 'center' }} variant="h3">
                Calculating Accuracy Score...
            </Typography>
        );
    }

    return (
        <Box sx={{ marginBottom: 2 }}>
            <Typography sx={{ marginBottom: 2, textAlign: 'center' }} variant="h3">
                Finding the Best Vendors for You...
            </Typography>
            <ScoreDisplay score={score} suggestion={suggestions[0]} />
        </Box>
    );
};

LoadingDisplay.propTypes = {
    project: PropTypes.object.isRequired,
};
