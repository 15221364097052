import React, { useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Box, IconButton, Typography } from '@og-pro/ui';
import { Edit as EditIcon } from '@mui/icons-material';

import { getContractDocBuilderRoute } from '../../../../helpers';
import { AgGridReact, LoadingSpinner } from '../../../../components';
import { loadContractAssembledDocuments } from '../../../../actions/contracts';
import { CollapsableBox } from './CollapsableBox';
import { StatusCellRenderer } from './StatusCellRenderer';
import { DocusignActions } from './DocusignActions';
import { CreateContractDocumentButton } from './CreateContractDocumentButton';
import { DownloadDocumentButton } from './DownloadDocumentButton';

export const AssembledAgreements = () => {
    const { contractId } = useParams();
    const dispatch = useDispatch();
    const gridApiRef = useRef(null);
    const handleGridReady = (params) => {
        gridApiRef.current = params.api;
    };
    const { isFetching, data, refetch } = useQuery({
        queryKey: ['AssembledAgreements', contractId],
        queryFn: () => dispatch(loadContractAssembledDocuments(contractId)),
        retry: false,
        refetchOnWindowFocus: false,
    });

    const reloadData = async () => {
        await refetch();
        gridApiRef.current.refreshCells({ force: true });
    };

    const styles = require('../index.scss');

    if (isFetching) {
        return (
            <Box py={4}>
                <LoadingSpinner noPadding size="small" useOpenGovStyle />
            </Box>
        );
    }

    const columns = [
        {
            field: 'type',
            headerName: 'Document Type',
            cellRendererFramework: () => <>Contract Package</>,
        },
        {
            field: 'title',
            headerName: 'File Name',
            cellRendererFramework: (params) => {
                return <Link to={getContractDocBuilderRoute(params.data)}>{params.value}</Link>;
            },
        },
        {
            field: 'created_at',
            headerName: 'Date Added',
            cellRendererFramework: (params) =>
                params.value !== null
                    ? new Date(params.value).toLocaleDateString('en-us', { timeZone: 'UTC' })
                    : null,
        },
        {
            field: 'status',
            cellRendererFramework: StatusCellRenderer,
        },
        {
            field: 'lastAction',
            headerName: 'Last Action',
            cellRendererFramework: () => {
                return <div>-</div>;
            },
        },
        {
            field: 'notes',
            headerName: 'Notes',
            cellRendererFramework: () => {
                return <div>-</div>;
            },
        },
        {
            cellClass: styles.iconCells,
            cellRendererFramework: (params) => (
                <Box alignItems="center" display="flex" justifyContent="flex-end">
                    <Box px={0.5}>
                        <DocusignActions contractDocument={params.data} reloadData={reloadData} />
                    </Box>
                    <Box px={0.5}>
                        <DownloadDocumentButton contractDocument={params.data} />
                    </Box>
                    <Box px={0.5}>
                        <IconButton
                            component={Link}
                            qaTag="contractDocuments-editAssembledDocument"
                            size="small"
                            to={getContractDocBuilderRoute(params.data)}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Box>
                </Box>
            ),
        },
    ];

    const defaultColDef = {
        editable: false,
        cellClass: styles.cell,
        flex: 1,
        headerClass: styles.header,
        suppressMenu: true,
    };
    return (
        <Box>
            <CollapsableBox
                actions={
                    <Box flex={1} textAlign="right">
                        <CreateContractDocumentButton />
                    </Box>
                }
                subtitle="View assembled documents and signature statuses with Docusign or upload main agreement documents."
                title="Assembled Contract Agreement"
            >
                {!data?.length && (
                    <Typography fontStyle="italic">No assembled contract agreements</Typography>
                )}
                {!!data?.length && (
                    <AgGridReact
                        columns={columns}
                        defaultColDef={defaultColDef}
                        domLayout="autoHeight"
                        getRowNodeId={(d) => d.id}
                        hideSideBar
                        onGridReady={handleGridReady}
                        rowHeight={52}
                        rows={data}
                        useOpenGovStyle
                    />
                )}
            </CollapsableBox>
        </Box>
    );
};
