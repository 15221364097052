import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';
import { Box, buildQaTag, Chip, Typography } from '@og-pro/ui';
import { Lock as LockIcon } from '@mui/icons-material';

import { formConfig } from './form';
import {
    AdditionalInformation,
    Attachments,
    CustomFieldsSection,
    GeneralInformation,
    PurchaseDetails,
    VendorSelection,
} from './FormComponents';
import {
    withRequisitionsCreateForm,
    withSectionFormErrorHandling,
} from './RequisitionsCreateFormHOC';
import { getRequisitionsCreateEnabledSections, getRequisitionsCreateSections } from '../selectors';
import {
    ADDITIONAL_INFORMATION,
    ATTACHMENTS,
    CUSTOM_FIELDS,
    EDIT_MODE,
    formSections,
    GENERAL_INFORMATION,
    PURCHASE_DETAILS,
    REVIEW_MODE,
    sectionsTypes,
    VENDOR_SELECTION,
} from '../../../../constants/requisitionsCreate';
import { useFocusedItemChange, useQueryParam } from '../../../../hooks';
import { setSectionDisabled, setSectionEnabled } from '../../../../actions/requisitionsCreate';
import { getRequisitionJS } from '../../../../selectors/govApp';
import {
    ReviewAdditionalInformation,
    ReviewAttachments,
    ReviewGeneralInformation,
    ReviewPurchaseDetails,
    ReviewVendorSelection,
} from './ReviewComponents';
import { Button } from '../../../../components';
import { qaTagPageName } from '../constants';
import { useRequisitionSectionClick } from './useRequisitionSectionClick';
import { CustomFormSnapshotContext } from './CustomFormSnapshotContext';
import { ReviewCustomFieldsSection } from './ReviewComponents/CustomFieldsSection';

const sectionComponents = {
    [GENERAL_INFORMATION]: withSectionFormErrorHandling(GeneralInformation, GENERAL_INFORMATION),
    [PURCHASE_DETAILS]: withSectionFormErrorHandling(PurchaseDetails, PURCHASE_DETAILS),
    [VENDOR_SELECTION]: withSectionFormErrorHandling(VendorSelection, VENDOR_SELECTION),
    [ADDITIONAL_INFORMATION]: withSectionFormErrorHandling(
        AdditionalInformation,
        ADDITIONAL_INFORMATION
    ),
    [CUSTOM_FIELDS]: withSectionFormErrorHandling(CustomFieldsSection, CUSTOM_FIELDS),
    [ATTACHMENTS]: withSectionFormErrorHandling(Attachments, ATTACHMENTS),
};

const reviewSectionComponents = {
    [GENERAL_INFORMATION]: ReviewGeneralInformation,
    [PURCHASE_DETAILS]: ReviewPurchaseDetails,
    [VENDOR_SELECTION]: ReviewVendorSelection,
    [ADDITIONAL_INFORMATION]: ReviewAdditionalInformation,
    [CUSTOM_FIELDS]: ReviewCustomFieldsSection,
    [ATTACHMENTS]: ReviewAttachments,
};

export const ConnectedRequisitionsCreateForms = ({
    change,
    disabled,
    isCurrentUserCreator,
    handleSubmit,
    saveRequisition,
    showFormValidation,
}) => {
    const dispatch = useDispatch();
    const handleEditClick = useRequisitionSectionClick();

    const styles = require('./index.scss');
    const sharedStyles = require('./shared.scss');

    const sections = useSelector(getRequisitionsCreateSections);
    const enabledSections = useSelector(getRequisitionsCreateEnabledSections);
    const requisition = useSelector(getRequisitionJS);
    const { customFields } = useContext(CustomFormSnapshotContext);

    useEffect(() => {
        if (requisition.flagResponses.length === 0) {
            dispatch(setSectionDisabled(ADDITIONAL_INFORMATION));
        } else {
            dispatch(setSectionEnabled(ADDITIONAL_INFORMATION));
        }
    }, [requisition.flagResponses.length]);

    useEffect(() => {
        if (customFields.length === 0) {
            dispatch(setSectionDisabled(CUSTOM_FIELDS));
        } else {
            dispatch(setSectionEnabled(CUSTOM_FIELDS));
        }
    }, [customFields]);

    const [, setActiveSectionKey] = useQueryParam('section');
    const [formMode] = useQueryParam('mode', EDIT_MODE);

    const handleFocusedSectionChange = (focusedItemId) => {
        if (!focusedItemId) {
            return;
        }

        setActiveSectionKey(focusedItemId);
    };

    useFocusedItemChange(enabledSections, handleFocusedSectionChange);

    const formErrors = useSelector((state) => getFormSyncErrors(formConfig.form)(state));

    const renderFormSection = (
        Component,
        section,
        index,
        sectionKey,
        sectionFormErrors,
        showEditButton
    ) => (
        <div className={styles.formContentContainer} id={sectionKey} key={sectionKey}>
            <div className={styles.formTitleContainer}>
                <div className={styles.sectionNumberContainer}>
                    {section.completed && !section.optional ? (
                        <span
                            className={classNames(
                                styles.sectionNumber,
                                styles.sectionNumberCompleted
                            )}
                            data-qa={buildQaTag(
                                `requisitionsCreate-section${index + 1}Completed`,
                                'icon'
                            )}
                        >
                            <i className={classNames('fa fa-check fa-inverse', styles.checkIcon)} />
                        </span>
                    ) : (
                        <span
                            className={classNames(styles.sectionNumber)}
                            data-qa={buildQaTag(
                                `requisitionsCreate-section${index + 1}Incomplete`,
                                'icon'
                            )}
                        >
                            {index + 1}
                        </span>
                    )}
                </div>
                <div className={styles.sectionTitleContainer}>
                    <h3>{section.name}</h3>
                    <p className={styles.formSubtitle}>{section.description}</p>
                </div>
                {section.internal && (
                    <Box display="flex" flexGrow={100} justifyContent="end">
                        <Chip
                            icon={<LockIcon />}
                            label="Internal Only"
                            size="medium"
                            variant="tag"
                        />
                    </Box>
                )}
                {showEditButton && (
                    <div className={styles.editButtonContainer}>
                        <Button
                            bsStyle="link"
                            className={styles.linkButton}
                            onClick={() => handleEditClick(sectionKey)}
                            qaTag={`${qaTagPageName}-edit-${section.type}`}
                            zeroPadding
                        >
                            <i className="fa fa-pencil-square-o" />
                            Edit
                        </Button>
                    </div>
                )}
            </div>
            <div className={styles.componentContainer}>
                <Component
                    change={change}
                    disabled={disabled}
                    formErrors={sectionFormErrors}
                    saveRequisition={saveRequisition}
                    showFormValidation={showFormValidation}
                />
            </div>
        </div>
    );

    return formMode === REVIEW_MODE || !isCurrentUserCreator ? (
        <div className={sharedStyles.requisitionsCreate}>
            <Typography variant="h2">Please review the information before submitting.</Typography>
            {sectionsTypes
                .filter((sectionKey) => {
                    const section = sections[sectionKey];

                    const Component = reviewSectionComponents[sectionKey];

                    if (!Component || section.disabled) {
                        return false;
                    }

                    return true;
                })
                .map((sectionKey, index) => {
                    const section = sections[sectionKey];

                    const Component = reviewSectionComponents[sectionKey];

                    return renderFormSection(
                        Component,
                        section,
                        index,
                        sectionKey,
                        undefined,
                        isCurrentUserCreator
                    );
                })}
        </div>
    ) : (
        <form className={sharedStyles.requisitionsCreate} onSubmit={handleSubmit}>
            {sectionsTypes
                .filter((sectionKey) => {
                    const section = sections[sectionKey];

                    if (section.disabled) {
                        return false;
                    }

                    if (formMode === REVIEW_MODE && formSections.includes(sectionKey)) {
                        return false;
                    }
                    return true;
                })
                .map((sectionKey, index) => {
                    const section = sections[sectionKey];

                    const Component = sectionComponents[sectionKey];

                    const sectionFormErrors = formErrors?.sections?.[sectionKey];

                    return renderFormSection(
                        Component,
                        section,
                        index,
                        sectionKey,
                        sectionFormErrors,
                        false
                    );
                })}
        </form>
    );
};

ConnectedRequisitionsCreateForms.propTypes = {
    change: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    isCurrentUserCreator: PropTypes.bool.isRequired,
    saveRequisition: PropTypes.func.isRequired,
    showFormValidation: PropTypes.bool,
};

export const RequisitionsCreateForms = withRequisitionsCreateForm()(
    ConnectedRequisitionsCreateForms
);
