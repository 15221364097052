import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import {
    getActionData,
    getByText,
    getDetails,
    getInformation,
    getPositionName,
} from '@og-pro/shared-config/requisitionLogs/helpers';

export const RequisitionLogItem = ({ requisition, requisitionLog }) => {
    const styles = require('./index.scss');

    const { bsStyle, icon, by = 'by', text } = getActionData(requisitionLog, requisition);
    const positionName = getPositionName(requisitionLog, requisition);
    const information = getInformation(requisitionLog);
    const details = getDetails(requisitionLog);
    const byText = getByText(requisitionLog, by, positionName);

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <span className={classNames(bsStyle && `text-${bsStyle}`)}>
                    {icon && <i className={classNames('fa', icon, styles.icon)} />}
                    {text}
                </span>
                {` ${byText}`}
            </div>
            <div className={styles.information}>{information}</div>
            {details && <div>{details}</div>}
        </div>
    );
};

RequisitionLogItem.propTypes = {
    requisition: PropTypes.object.isRequired,
    requisitionLog: PropTypes.object.isRequired,
};
