import { Refresh as RefreshIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React from 'react';
import { Box, Button, Typography } from '@og-pro/ui';

import { qaTagName } from './constants';
import { loadingContainerStyle, loadingErrorContainerStyle, vendorsListStyle } from './styles';
import { LoadingDisplay } from './LoadingDisplay';
import { VendorCard } from './VendorCard';
import { LoadingSpinner } from '../../../components';

export const Body = ({ isLoading, loadingError, project, retrySearch, vendors }) => {
    if (isLoading) {
        return (
            <Box sx={loadingContainerStyle}>
                <Box
                    component={LoadingSpinner}
                    noPadding
                    sx={{ paddingBottom: 2 }}
                    useOpenGovStyle
                />
                <LoadingDisplay project={project} />
            </Box>
        );
    }

    if (loadingError) {
        return (
            <Box sx={loadingErrorContainerStyle}>
                <Box sx={{ marginBottom: 2 }}>
                    <Typography sx={{ marginBottom: 1 }} variant="h3">
                        Error Generating Data
                    </Typography>
                    <Typography>{loadingError}</Typography>
                </Box>
                <Button
                    color="primary"
                    onClick={retrySearch}
                    qaTag={`${qaTagName}-tryAgainError`}
                    startIcon={<RefreshIcon />}
                    variant="outlined"
                >
                    Try Again
                </Button>
            </Box>
        );
    }

    if (vendors.length === 0) {
        return (
            <Box sx={loadingErrorContainerStyle}>
                <Box sx={{ marginBottom: 2 }}>
                    <Typography sx={{ marginBottom: 1 }} variant="h3">
                        No results found :(
                    </Typography>
                    <Typography>There was likely an issue generating your search data</Typography>
                </Box>
                <Button
                    color="primary"
                    onClick={retrySearch}
                    qaTag={`${qaTagName}-tryAgainNoResults`}
                    startIcon={<RefreshIcon />}
                    variant="outlined"
                >
                    Try Again
                </Button>
            </Box>
        );
    }

    return (
        <>
            <Box sx={{ paddingBottom: 2 }}>
                <Button
                    color="primary"
                    onClick={retrySearch}
                    qaTag={`${qaTagName}-redoSearch`}
                    size="small"
                    startIcon={<RefreshIcon />}
                    variant="outlined"
                >
                    Redo Search
                </Button>
            </Box>
            <Box sx={vendorsListStyle}>
                {vendors.map((vendor, index) => (
                    <VendorCard key={index} timeout={index * 600 + 300} vendor={vendor} />
                ))}
            </Box>
        </>
    );
};

Body.propTypes = {
    isLoading: PropTypes.bool,
    loadingError: PropTypes.string,
    project: PropTypes.object.isRequired,
    retrySearch: PropTypes.func.isRequired,
    vendors: PropTypes.array.isRequired,
};
