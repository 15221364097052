import {
    CheckCircleOutline as CheckCircleOutlineIcon,
    StarOutline as StarOutlineIcon,
    InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@og-pro/ui';

import { scoreContainerStyle, scoreListItemStyle, scoreListItemDividerStyle } from './styles';

const ScoreDisplayItem = ({ Icon, divider, text }) => {
    return (
        <Box sx={divider ? scoreListItemDividerStyle : scoreListItemStyle}>
            <Icon />
            <Typography variant="bodySmall">{text}</Typography>
        </Box>
    );
};

ScoreDisplayItem.propTypes = {
    Icon: PropTypes.elementType.isRequired,
    divider: PropTypes.bool,
    text: PropTypes.node.isRequired,
};

export const ScoreDisplay = ({ score, suggestion }) => {
    const [showSuggestion, setShowSuggestion] = useState(false);

    // This is purely to provide some visual progress during the very long wait time for the data
    useEffect(() => {
        // Suggestion for improving accuracy will display after this timeout is reached
        setTimeout(() => {
            setShowSuggestion(true);
        }, 2000);
    }, []);

    let textColor = 'text-danger';
    if (score >= 40) {
        textColor = 'text-warning';
    }
    if (score >= 80) {
        textColor = 'text-success';
    }

    return (
        <Box sx={scoreContainerStyle}>
            <ScoreDisplayItem
                Icon={StarOutlineIcon}
                text={
                    <strong>
                        Accuracy score:&nbsp;<span className={textColor}>{score}</span>
                    </strong>
                }
            />
            {showSuggestion && suggestion && (
                <ScoreDisplayItem
                    Icon={InfoOutlinedIcon}
                    divider
                    text={
                        <>
                            <strong>{suggestion}</strong> to improve recommendations.
                        </>
                    }
                />
            )}
            {showSuggestion && !suggestion && (
                <ScoreDisplayItem
                    Icon={CheckCircleOutlineIcon}
                    divider
                    text={<strong>Great job, you have a well detailed project!</strong>}
                />
            )}
        </Box>
    );
};

ScoreDisplay.propTypes = {
    score: PropTypes.number.isRequired,
    suggestion: PropTypes.string,
};
