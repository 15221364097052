import { Launch as LaunchIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fade } from 'react-bootstrap';
import { Box, Card, CardContent, Link, Typography } from '@og-pro/ui';

import { ContactAddress } from '../../../components';
import { vendorCardContainerStyle, vendorDescriptionStyle } from './styles';

export const VendorCard = ({ timeout, vendor }) => {
    const { address, city, description, stateAbbreviation, vendorName, website, zipCode } = vendor;

    const [show, setShow] = useState(false);

    // This logic along with the `timeout` prop is here to make the items fade in one at a time.
    // It's purely for display effect.
    useEffect(() => {
        setTimeout(() => setShow(true), timeout);
    }, []);

    return (
        <Fade in={show}>
            <Card>
                <CardContent sx={vendorCardContainerStyle}>
                    <Typography variant="h4">{vendorName}</Typography>
                    {website && (
                        <Box>
                            <Link href={website} rel="noreferrer" target="_blank" underline="hover">
                                {website} <LaunchIcon fontSize="inherit" />
                            </Link>
                        </Box>
                    )}
                    <Typography>
                        <ContactAddress
                            address1={address}
                            city={city}
                            state={stateAbbreviation}
                            zipCode={zipCode}
                        />
                    </Typography>
                    <Typography sx={vendorDescriptionStyle} variant="bodyXSmall">
                        {description}
                    </Typography>
                </CardContent>
            </Card>
        </Fade>
    );
};

VendorCard.propTypes = {
    timeout: PropTypes.number.isRequired,
    vendor: PropTypes.object.isRequired,
};
